<template>
    <div>
        <div class="header">
            资讯管理
        </div>
        <div class="manage">
            <div class="search">
                <a-form layout="inline" :form="form" @submit="handleSearch">
                    <a-form-item>
                        <a-input
                        v-decorator="[
                                'name'
                            ]"
                        placeholder="请输入资讯名称关键字"
                        autocomplete="off"
                        ></a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-select
                            style="width: 150px;"
                            v-decorator="[
                                'community_id'
                                ]"
                            placeholder="请选择社区"
                            showSearch
                            option-filter-prop="children"
                            :filter-option="filterOption"
                            >
                            <a-select-option
                                v-for="(item, index) in communities"
                                :key="index"
                                :value="item.id"
                            >{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item>
                        <a-button html-type="submit">搜索</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="handleRefesh" html-type="button">刷新</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="handleById('', 'new')" html-type="button">新增资讯</a-button>
                    </a-form-item>
                </a-form>
            </div>
            <a-table
                bordered
                :columns="columns"
                :dataSource="data"
                :pagination="false"
                :loading="loading"
                :scroll="{ x: true}"
            >
                <template slot="apply" slot-scope="text, record">
                    <a-switch :checked="record.apply" @change="onChange(record)" />
                </template>
                <span slot="action" slot-scope="text, record">
                    <a href="javascript:;" @click="handleById(record, 'edit')">编辑</a>
                    <a href="javascript:;" @click="handleCommity(record)">配置社区</a>
                    <a href="javascript:;" class="delete" @click="handleDelete(record)">删除</a>
                </span>
            </a-table>
            <Pagination :current="current" :total="total" @handleChange="handleChange" v-show="total > 20" />
            <a-modal
                title="配置社区"
                class="modal"
                v-model="visible"
                @ok="handleOk"
                @cancel="handleCancel"
                okText="确认"
                cancelText="取消"
                width="450px"
                >
                <a-form :form="form1" :label-col="{ span: 4}" :wrapper-col="{ span: 20 }">
                    <a-form-item label="社区">
                        <a-select
                            v-decorator="[
                                'community_id'
                                ]"
                            placeholder="请选择社区"
                            showSearch
                            option-filter-prop="children"
                            :filter-option="filterOption"
                            >
                            <a-select-option
                                v-for="(item, index) in communities"
                                :key="index"
                                :value="item.id"
                            >{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-form>
            </a-modal>
        </div>
    </div>
</template>

<script>
import Pagination from "./../../components/Pagination.vue";
import { InfoModel } from "../../model/info";
import formatTime from './../../utils/formatTime'
const infoModel = new InfoModel();
const columns = [
  {
    title: "资讯名称",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "发布社区",
    dataIndex: "community_name",
    key: "community_name"
  },
   {
    title: "链接地址",
    dataIndex: "link_addr",
    key: "link_addr"
  },
  {
    title: "发布日期",
    dataIndex: "apply_at",
    key: "apply_at"
  },
  {
    title: "是否发布",
    dataIndex: "apply",
    key: "apply",
    scopedSlots: { customRender: "apply" }
  },
  {
    title: "创建",
    dataIndex: "created_at",
    key: "created_at"
  },
  {
    title: "操作",
    dataIndex: "",
    width: 250,
    key: "action",
    scopedSlots: {
      customRender: "action"
    }
  }
];

const data = [] 
export default {
    data() {
        return {
            form: this.$form.createForm(this, {
                name: "horizontal_login"
            }),
            form1: this.$form.createForm(this, {
                name: "horizontal_login"
            }),
            columns,
            data,
            total: 0,
            current: 1,
            id: 0,
            name: '',
            communityId: 0,
            visible: false,
            communities: [],
            loading: true
        }
    },

    mounted() {
        this._queryCommunities()
        this.current = 1
        this.communityId = 0
        this.name = ''
        this._queryActivities({name: this.name, community_id: this.communityId,  page: this.current})
    },

    methods: {
        handleRefesh() {
            this._queryActivities({name: '', community_id: 0, page: 1})
            this.form.resetFields()
        },

        handleCommity(record) {
            this.visible = true
            this.id = record.id
        },

        handleCancel() {
             this.visible = false
             this.form1.resetFields()
        },

        handleById(record, item) {
            let flag = item == 'new' ? 'new' : record.id
            console.log(flag)

            this.$router.push(`/info/${flag}`)
        },

        handleSearch (e) {
            e.preventDefault()
            this.form.validateFields((err, values) => {
                if (!err) {
                console.log(values)
                this.name = values.name
                values.page = 1
                this.current = 1
                this.communityId = values.community_id
                this._queryActivities(values)
                }
            })
        },

        handleOk(e) {
            e.preventDefault()
            this.form1.validateFields((err, values) => {
                if (!err) {
                    console.log(values)
                    values.id = this.id
                    values.type = 2
                    infoModel.updateActivityByCondition(values).then(res => {
                        console.log(res, 222)
                        if(res.code == '000000') {
                            this.$notification.success({
                                message: "提示",
                                description: res.message,
                                duration: 2
                            })
                            this.visible = false
                            this.form1.resetFields()
                            this._queryActivities({name: '', community_id: 0, page: this.current})
                        } else {
                            this.$notification.error({
                                message: "提示",
                                description: res.message,
                                duration: 2
                            })
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                }
            })
        },

        handleChange(page) {
            this.current = page
            this._queryActivities({name: this.name, community_id: this.communityId, page: this.current})
        },

        handleDelete(record) {
            console.log(record)
            const confirmText = ["确定删除资讯吗？"];
            const newDatas = [];
            const h = this.$createElement;
            for (const i in confirmText) {
                newDatas.push(h("p", null, confirmText[i]));
            }
            this.$confirm({
                content: h("div", null, newDatas),
                okText: "确认",
                class: "deleteBtn",
                cancelText: "取消",
                onOk: () => {
                    infoModel.deleteActivity({id: record.id}).then(res => {
                        if(res.code == '000000') {
                            this.$notification.success({
                                message: "提示",
                                description: res.message,
                                duration: 2
                            });
                            this._queryActivities({name: '', community_id: 0, page: 1})
                        } else {
                            this.$notification.error({
                                message: "提示",
                                description: res.message,
                                duration: 2
                            });
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                },
                onCancel() {}
            });
        },

        onChange(record) {
            let data = {}
            data.id = record.id
            data.type = 1
            data.apply = !record.apply
            infoModel.updateActivityByCondition(data).then(res => {
                if (res.code == '000000') {
                    this.$notification.success({
                        message: "提示",
                        description: res.message,
                        duration: 2
                    });
                    // this.querySoftList();
                    record.apply = !record.apply
                } else {
                    this.$notification.error({
                        message: "提示",
                        description: res.message,
                        duration: 2
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        },

        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },

        _queryCommunities() {
            infoModel.getCommunities().then(res => {
                if(res.code == '000000') {
                    this.communities = res.data
                } else {
                    this.$notification.success({
                        message: "提示",
                        description: res.message,
                        duration: 2
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        },

        _queryActivities(params) {
            this.loading = true
            infoModel.getActivities(params).then(res => {
                if(res.code == '000000') {
                    res.data.map(item => {
                        item.key = item.id
                        if(item.community_id == 15) {
                            item.community_name = item.community_name + '街道'
                        } else {
                            item.community_name = item.community_name + '社区'
                        }
                        item.created_at = formatTime(item.created_at)
                        return item
                    })
                    this.data = res.data
                    this.total = res.totalRecord
                } else {
                    this.$notification.error({
                        message: "提示",
                        description: res.message,
                        duration: 2
                    })
                }
                this.loading = false
            }).catch(err => {
                console.log(err)
                this.loading = false
            })
        }
    },

    components: {
        Pagination
    }
}

</script>


<style scoped>
    .manage {
        padding: 15px;
    }

    .search {
        box-shadow: 0 0  3px 3px #e6c2c2;
    }

    .header {
        font-size: 16px;
    }

    .search, .header {
        padding: 5px 15px;
    }

    .search /deep/ .ant-input {
        width: 250px;
    }

    .search /deep/ .ant-input:focus,
    .search /deep/ .ant-input:hover {
        border-color: #4DD493;
        box-shadow: 0 0 0 2px rgb(77 212 147 / 20%);
    }

    .search /deep/ .ant-btn,
    .modal /deep/ .ant-btn:nth-of-type(2) {
        border-color: #4DD493;
        background-color: #4DD493;
        color: #fff;
    }

    .modal /deep/ .ant-btn:nth-of-type(1):hover,
    .modal /deep/ .ant-btn:nth-of-type(1):focus {
        color: #4DD493 !important;
        border-color: #4DD493 !important;
    }

    .manage a {
        color: #4DD493;
        margin-left: 10px;
        margin-right: 10px;
    }

    a.delete {
        color: #FF0000;
    }

    .manage /deep/ .ant-table-wrapper {
        margin-top: 20px;
    }

    .manage /deep/ .ant-switch-checked {
        background-color: #4DD493;
    }

</style>

<style>
    .deleteBtn .ant-btn:nth-of-type(1):hover,
    .deleteBtn .ant-btn:nth-of-type(1):focus {
        color: #4DD493 !important;
        border-color: #4DD493 !important;
    }
</style>
